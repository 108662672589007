@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;
@layer base {
  html {
    @apply text-surface;
    @apply bg-white;
  }
  html.dark {
    @apply text-neutral-50;
    @apply bg-body-dark;
  }
}

@tailwind components;
@tailwind utilities;

p {
  @apply leading-[1.6];
}