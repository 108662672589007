/* Previouse */
#open {
    overflow: hidden;
    visibility: visible;
  }
  
  #close {
    overflow: hidden;
    display: none;
  }
  
  .nav-Link {
    height: auto; 
    display: flex;
    align-items: center;
    
  }
  #logo-Web{
    width: 20%;
    height: 99%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 95%;
    
  }
  *{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}


.nav-area {
  display: flex;
  align-items: center;
  max-width: 100%;
  margin: 0 auto;
  padding: 0 20px;
  height: 58px;

}



.menus {
  display: flex;
  list-style: none;

  width: 100%;
}

.menu-items {
  position: relative;

  
}

.menu-items a {
  display: block;
  font-size: inherit;
  color: inherit;
  text-decoration: none;
  
}

.menu-items button {
  color: inherit;
  font-size: inherit;
  border: none;
  background-color: transparent;
  cursor: pointer;
  width: 100%;
  
}

.menu-items a,
.menu-items button {
  text-align: left;
  padding: 0.6rem 0.5rem;
  border-radius: 10px;

}


.arrow::after {
        content: "";
        display:inline-block;
        margin-left: 0.7em;
        vertical-align: 0.09em;
        border-top: 0.43em solid;
        border-right: 0.33em solid transparent;
        border-left: 0.33em solid transparent;
        transform:rotate(180deg);
        transition: all 0.4s;

    }
#itemNav:hover .arrow::after{
  transform: rotate(0deg);
  transition: all 0.4s;

}
    .dropdown {
        position: absolute;
        right: 3;
        left: auto;
        box-shadow: 0 10px 15px - 3px rgba(46, 41, 51, 0.08), 0 4px 6px - 2px rgba(71, 63, 79, 0.16);
        font-size: 0.8rem;
        z-index: 9999;
        min-width: 10rem;
        padding: 0.5rem 0;
        list-style: none;
        background-color: #fff;
        border-radius: 0.5rem;
        display: none;
    }

    .dropdown.show {
        display: block;
    }
    .dropdown.show:hover{
      color: black;
    }
    .dropdown.dropdown-submenu {
        position: absolute;
        left: 100% ;
        top: -7px;
    }

    @media (min-width : 768px){
      .forFooter{
        height: 500px;
      }
    }
    